export const ColorNames = {
  default: 'default',
  purple: 'purple',
  blue: 'blue',
  green: 'green',
  yellow: 'yellow',
  orange: 'orange',
  grey: 'grey',
  red: 'red',
  deep_red: 'Deep red',
  yellow_green: 'Yellow green'
};

export const colors = {
  [ColorNames.default]: '#333333',
  [ColorNames.purple]: '#AA00FF',
  [ColorNames.blue]: '#2780E3',
  [ColorNames.green]: '#31A136',
  [ColorNames.yellow]: '#FFC300',
  [ColorNames.orange]: '#FF7518',
  [ColorNames.grey]: '#616161',
  [ColorNames.red]: '#E70202',
  [ColorNames.deep_red]: '#920000',
  [ColorNames.yellow_green]: '#C0CA33'
};
