import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  from,
  split,
  fromPromise
} from '@apollo/client/core';
import WebSocketLink from './WebSocketLink';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { cache } from './apollo-cache';
import axios from 'axios';
import schema from '@/modules/auth/api/schemas.graphql';
import typeDefs from '../../graphql/sdl.graphql';

import useErrors from '@/compositions/errors';

const { error } = useErrors();

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};
let isRefreshing = false;
let pendingRequests = [];

const setIsRefreshing = value => {
  isRefreshing = value;
};

const addPendingRequest = pendingRequest => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};
export let apiClient;

const getNewToken = operation => {
  localStorage.removeItem('authToken');

  operation.setContext(() => ({
    headers: {}
  }));

  return apiClient
    .mutate({
      mutation: schema.authAccessToken,
      variables: {
        input: {
          userRefreshToken: localStorage.getItem('refreshToken'),
          accessTokenExpiration: 24 * 60,
          profileTags: ['application', 'monitor', 'user profile']
        }
      },
      fetchPolicy: 'no-cache'
    })
    .then(response => {
      const authToken = response.data.authAccessToken.jwtToken;

      if (authToken) {
        localStorage.setItem('authToken', authToken);
        return authToken;
      } else {
        return Promise.reject(new Error('error getting token'));
      }
    });
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const newHeaders = {};

  if (getAuthToken()) {
    newHeaders.authorization = `Bearer ${getAuthToken()}`;
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...newHeaders
    }
  }));

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: window.__pixelConfig.APP_API
});

export const wsLink = new WebSocketLink({
  url: window.__pixelConfig.WS_API,
  lazy: true,
  connectionParams: () => ({
    authorization: `Bearer ${getAuthToken()}`
  }),
  keepAlive: 30000,
  retryAttempts: Infinity,
  retryWait: async function waitForServerHealthyBeforeRetry() {
    await new Promise(resolve =>
      setTimeout(resolve, 1000 + Math.random() * 3000)
    );
  },
  on: {
    connected: _socket => {
      console.log('websocket connected');
    },
    closed: _socket => {
      console.log('websocket closed');
    },
    error: _socket => {
      console.log('websocket error');
    }
  }
});

const AUTH_ERRORS = ['TokenExpiredError', 'JsonWebTokenError'];

const errorHandle = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          if (window.location.pathname !== '/login') window.location = '/login';
        }
        if (operation.operationName === 'getTranslations') return;
        if (AUTH_ERRORS.includes(err?.name)) {
          if (!isRefreshing) {
            setIsRefreshing(true);
            return fromPromise(
              getNewToken(operation).catch(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                localStorage.removeItem('authToken');
                if (window.location.pathname !== '/login')
                  window.location = '/login';
                localStorage.setItem(
                  'lastUrlPathname',
                  window.location.pathname
                );
                return forward(operation);
              })
            ).flatMap(() => {
              resolvePendingRequests();
              setIsRefreshing(false);

              return forward(operation);
            });
          } else {
            return fromPromise(
              new Promise(resolve => {
                addPendingRequest(() => resolve());
              })
            ).flatMap(() => {
              return forward(operation);
            });
          }
        } else {
          // show(err.message);
          error.value = err.message;
        }
      }
    }
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all'
  },
  query: {
    errorPolicy: 'all'
  },
  ssrMode: false,
  ssrForceFetchDelay: 0,
  connectToDevTools:
    process.env.NODE_ENV !== 'production' || !!localStorage.getItem('devtools')
};

const retryLink = new RetryLink();

apiClient = new ApolloClient({
  link: from([errorHandle, authMiddleware, retryLink, splitLink]),
  cache: cache,
  defaultOptions,
  typeDefs
});

export const mediaClient = {
  upload(mediaId, file) {
    const formData = new FormData();
    formData.append('uploaded_file', file);
    return axios.request({
      method: 'POST',
      url: `${
        window.__pixelConfig.APP_MEDIA_SERVER
      }/upload/${mediaId}/${getAuthToken()}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      // onUploadProgress: function(progressEvent) {
      //   let percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   // console.log(percentCompleted);
      // },
      data: formData
    });
  },

  getImageUrl(mediaId) {
    return mediaId
      ? `${
          window.__pixelConfig.APP_MEDIA_SERVER
        }/download/${mediaId}/${getAuthToken()}`
      : '';
  }
};

window.$apiClient = apiClient;
