import { ref, computed } from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
import { baseLayersDefinition } from '@/compositions/map/base-layer';
import { objectPropertyService, objectService } from '@/modules/common/api';
import { prepareObject } from '@/provider/utils';
import { inject, provide } from 'vue-demi';
import { getTheme } from '@/modules/account/useTheme';

const defaultProgramSettings = [
  {
    key: 'programDefaultPosition',
    value: [-0.12766, 51.507351]
  },
  {
    key: 'programDefaultRotation',
    value: 0
  },
  {
    key: 'programDefaultScale',
    value: 4
  },
  {
    key: 'programSizeOfObjectCardOnMap',
    value: 'medium'
  },
  {
    key: 'programSizeOfObjectsOnMap',
    value: 'medium'
  },
  {
    key: 'programMap',
    value: baseLayersDefinition[1].id // no map
  },
  {
    key: 'programAvailableMaps',
    value: { osm: true, google_satellite: true }
  },
  {
    key: 'programDefaultGeotag',
    value: ''
  },
  {
    key: 'programDefaultObjectGroup',
    value: ''
  },
  {
    key: 'programAllowAllGeotags',
    value: false
  },
  {
    key: 'programDisplayGeozonesByDefault',
    value: false
  },
  {
    key: 'programDisplayLandmarksByDefault',
    value: false
  },
  {
    key: 'programOsmUrl',
    value: ''
  }
];

const themes = [
  { id: 1, text: 'Light', value: false },
  { id: 2, text: 'Dark', value: true }
];

const languages = [
  { id: 1, text: 'English', value: 'en' },
  { id: 2, text: 'Chinese', value: 'cn' },
  { id: 3, text: 'Thai', value: 'th' }
];

const sizes = [
  { id: 1, text: 'Small', value: 'small' },
  { id: 2, text: 'Medium', value: 'medium' },
  { id: 3, text: 'Large', value: 'large' }
];

function createProgramSettingsStore() {
  const programSettings = ref(null);
  const centerPosition = ref(['', '']);

  const { getUserProfileId } = useAuth();

  const theme = ref(getTheme());

  const language = ref(languages[0]);

  const maps = computed(() => {
    const availableMaps =
      programSettings.value?.programAvailableMaps?.value || {};
    return baseLayersDefinition.filter(
      map => !!availableMaps[map.id] || map.id === 'empty'
    );
  });

  const load = async () => {
    const id = await getUserProfileId();

    programSettings.value = prepareObject(
      await objectService.fetch(id, { fetchPolicy: 'network-only' })
    );
    const position = programSettings.value['programDefaultPosition'].value;

    programSettings.value['programDefaultPosition'].value = position.split(',');

    defaultProgramSettings.forEach(({ key, value }) => {
      // if we have program setting but value is not defined than use def value
      if (
        programSettings.value[key].id &&
        (programSettings.value[key].value === undefined ||
          programSettings.value[key].value === null)
      ) {
        programSettings.value[key].value = value;
      }
    });
  };

  const update = async properties => {
    await objectPropertyService.updateValues(properties);
    await load();
  };

  return {
    theme,
    themes,
    language,
    close,
    languages,
    sizes,
    centerPosition,
    programSettings,
    update,
    maps,
    load
  };
}

export const ProgramSettingsProviderSymbol = Symbol(
  'Program settings identifier'
);

export const useProgramSettingsProvider = () => {
  provide(ProgramSettingsProviderSymbol, createProgramSettingsStore());
};

export function useProgramSettings() {
  return inject(ProgramSettingsProviderSymbol);
}
