export const getTheme = () => {
  try {
    return Boolean(JSON.parse(localStorage.getItem('theme') || ''));
  } catch {
    return false;
  }
};

export const setTheme = isDark => {
  localStorage.setItem('theme', isDark);
};
