<template>
  <v-app>
    <v-progress-linear v-if="isAppDataLoading" indeterminate />
    <slot />
    <popup-stack />
    <snack-bar />
  </v-app>
</template>

<script>
import PopupStack from './popup-stack';
import SnackBar from './snack-bar';
import { useAuth } from '@/modules/auth/api';
import { onBeforeUnmount, onMounted } from '@vue/composition-api';
export default {
  name: 'LayoutBase',
  components: {
    PopupStack,
    SnackBar
  },
  setup() {
    const resizeHandler = () => {
      const wrapper = document.querySelector('.v-application--wrap');
      if (wrapper) {
        wrapper.style['min-height'] = `${window.innerHeight}px`;
        wrapper.style['overflow'] = 'hidden';
      }
      window.addEventListener('resize', () => {
        console.log('resize base');
        wrapper.style['min-height'] = `${window.innerHeight}px`;
        wrapper.style['overflow'] = 'hidden';
      });
    };
    onMounted(() => {
      resizeHandler();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    return {
      isAppDataLoading: useAuth().isAppDataLoading
    };
  }
};
</script>

<style></style>
