<template>
  <v-snackbar v-model="showed" v-bind="options">
    {{ text }}

    <template #action="{ attrs }">
      <v-btn icon text v-bind="attrs" @click="hide">
        <v-icon>
          $close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useSnackBar } from '@/compositions/snackBar';
export default {
  name: 'SnackBar',
  setup() {
    return {
      ...useSnackBar()
    };
  }
};
</script>

<style></style>
