import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { ColorNames, colors } from './utils/data';
import { Feature } from 'ol';
import { LineString, MultiPoint, Point, MultiLineString } from 'ol/geom';
import { transformCoordinates } from '@/compositions/map/utils';
import { getVectorContext } from 'ol/render';
import { ref } from 'vue-demi';

export function useMapTrack(map) {
  const isPlaying = ref(false);
  const isAnimatingMode = ref(false);
  const trackPoints = ref([]);
  let currentTrackLineIndex = ref(0);
  let speed = ref(100);
  const mainColor = colors[ColorNames.blue];
  const secondaryColor = '#9e9e9e';
  const borderColor = '#ffffff';

  // Track points
  const trackPointsFeature = new Feature();
  const selectedTrackPointFeature = new Feature();
  const selectedTrackPointStyle = new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: ColorNames.red
      }),
      stroke: new Stroke({ color: mainColor, width: 2 })
    })
  });

  // track point style
  trackPointsFeature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: borderColor
        }),
        stroke: new Stroke({ color: mainColor, width: 1 })
      })
    })
  );

  // Track

  const track = new Feature();

  let overlayTrackGeometry = new LineString([]);
  const overlayTrack = new Feature({
    geometry: overlayTrackGeometry
  });

  let overlayTrackMarkerGeometry = new Point([]);
  const overlayTrackMarker = new Feature({
    geometry: overlayTrackMarkerGeometry
  });
  const overlayTrackMarkerStyle = new Style({
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: mainColor
      })
    })
  });
  overlayTrackMarker.setStyle(overlayTrackMarkerStyle);

  // Track style active
  const trackStyleActive = new Style({
    stroke: new Stroke({ color: mainColor, width: 4 })
  });

  // Track style inactive
  const trackStyleInactive = new Style({
    stroke: new Stroke({ color: secondaryColor, width: 4 })
  });

  const source = new VectorSource({
    features: []
  });

  const trackLayer = new VectorLayer({
    source: source,
    wrapX: false
  });

  const infoSource = new VectorSource({
    features: []
  });

  const infoLayer = new VectorLayer({
    source: infoSource,
    wrapX: false,
    style: function(feature) {
      const notUsed = feature.get('notUsed');
      return [
        new Style({
          image: new Icon({
            src: require('@/assets/pin.png'),
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            scale: 0.5,
            color: notUsed ? '#2780E3' : '#FF7518'
          })
        }),
        new Style({
          image: new Icon({
            src: require('@/assets/Icons/car.svg'),
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            anchor: [0.5, 46]
          })
        })
      ];
    }
  });

  const setMapToTrackCenter = () => {
    if (track) {
      map.getView().fit(track.getGeometry(), {
        padding: [100, 100, 100, 100],
        duration: 700
      });
    }
  };

  const setTrack = points => {
    clearTrack();

    if (!points.length) return;
    if (points.length === 1) {
      points.push(points[0]);
    }
    trackPoints.value = points.map(transformCoordinates);

    const transformedPoints = trackPoints.value.reduce(
      (acc, cur, index, arr) => {
        if (index === arr.length - 1) return acc;
        acc.push([arr[index], arr[index + 1]]);
        return acc;
      },
      []
    );

    track.setGeometry(new MultiLineString(transformedPoints));
    track.setStyle(trackStyleActive);
    source.addFeature(track);

    trackPointsFeature.setGeometry(new MultiPoint(trackPoints.value));
    source.addFeature(trackPointsFeature);
    source.addFeature(selectedTrackPointFeature);

    source.addFeature(overlayTrack);
    source.addFeature(overlayTrackMarker);
  };

  const clearTrack = () => {
    source.clear();
    infoSource.clear();
    stopPlayTrack();
    selectedTrackPointFeature.setStyle(null);
  };

  const setTrackVisible = visibleTrack => {
    track.setStyle(
      visibleTrack
        ? isAnimatingMode.value
          ? trackStyleActive
          : trackStyleInactive
        : []
    );
  };

  let lastTime;
  let distance = 0;

  const moveFeature = event => {
    const time = event.frameState.time;
    const elapsedTime = time - lastTime;
    if (isPlaying.value) {
      distance = distance + (speed.value * elapsedTime) / 1e6;
    }
    lastTime = time;

    if (distance >= 1) {
      distance = 0;
      const currentCoordinate = track
        .getGeometry()
        .getLineString(currentTrackLineIndex.value);
      overlayTrackGeometry.appendCoordinate(currentCoordinate);

      if (
        currentTrackLineIndex.value ==
        track.getGeometry().getLineStrings().length - 1
      ) {
        distance = 1;
      } else {
        currentTrackLineIndex.value += 1;
      }
    }

    if (overlayTrackGeometry.getCoordinates().length < 2) {
      overlayTrackGeometry.setCoordinates([
        track
          .getGeometry()
          .getLineString(currentTrackLineIndex.value)
          .getFirstCoordinate(),
        track
          .getGeometry()
          .getLineString(currentTrackLineIndex.value)
          .getFirstCoordinate()
      ]);
    }
    const currentCoordinate = track
      .getGeometry()
      .getLineString(currentTrackLineIndex.value)
      .getCoordinateAt(distance);
    // const coordinates = overlayTrackGeometry.getCoordinates().slice(0, -1)
    const coordinates = trackPoints.value.slice(
      0,
      currentTrackLineIndex.value + 1
    );
    overlayTrackGeometry.setCoordinates([...coordinates, currentCoordinate]);
    overlayTrackMarkerGeometry.setCoordinates(currentCoordinate);
    const vectorContext = getVectorContext(event);
    vectorContext.setStyle(trackStyleActive);
    vectorContext.drawGeometry(overlayTrackGeometry);
    vectorContext.setStyle(overlayTrackMarkerStyle);
    vectorContext.drawGeometry(overlayTrackMarkerGeometry);
    // tell OpenLayers to continue the postrender animation
    map.render();
    if (
      currentTrackLineIndex.value ==
        track.getGeometry().getLineStrings().length - 1 &&
      distance === 1
    ) {
      currentTrackLineIndex.value += 1;
      distance = 0;
      pausePlayTrack();
    }
  };

  const startPlayTrack = () => {
    isPlaying.value = true;
    track.setStyle(trackStyleInactive);
    overlayTrack.setStyle(trackStyleActive);
    overlayTrackMarker.setGeometry(null);
    overlayTrack.setGeometry(null);

    lastTime = Date.now();
    trackLayer.on('postrender', moveFeature);
  };

  const prevTrackLine = () => {
    if (
      (distance < 0.05 && isPlaying.value) ||
      (distance === 0 && !isPlaying.value)
    ) {
      currentTrackLineIndex.value =
        currentTrackLineIndex.value > 0 ? currentTrackLineIndex.value - 1 : 0;
    }
    distance = 0;
    if (!isPlaying.value) {
      lastTime = Date.now();
      trackLayer.once('postrender', event => moveFeature(event));
      map.render();
    }
  };

  const nextTrackLine = () => {
    if (
      track.getGeometry().getLineStrings().length ===
      currentTrackLineIndex.value
    )
      return;
    distance = 1;
    if (!isPlaying.value) {
      lastTime = Date.now();
      trackLayer.once('postrender', event => moveFeature(event));
      map.render();
    }
  };

  const pausePlayTrack = () => {
    isPlaying.value = false;
    overlayTrack.setGeometry(overlayTrackGeometry);
    overlayTrackMarker.setGeometry(overlayTrackMarkerGeometry);
    trackLayer.un('postrender', moveFeature);
  };

  const stopPlayTrack = () => {
    isPlaying.value = false;
    currentTrackLineIndex.value = 0;
    distance = 0;
    overlayTrackGeometry.setCoordinates([]);
    overlayTrack.setGeometry(overlayTrackGeometry);
    overlayTrackMarkerGeometry.setCoordinates([]);
    overlayTrackMarker.setGeometry(overlayTrackMarkerGeometry);
    trackLayer.un('postrender', moveFeature);
  };

  const setAnimatingMode = mode => {
    isAnimatingMode.value = mode;
    if (mode) {
      stopPlayTrack();
    } else {
      track.setStyle(trackStyleActive);
    }
  };

  const getBeaconFeature = ({ sourcePosition: { lat, lon } }) => {
    return new Feature({
      geometry: new Point(transformCoordinates([lon, lat]))
    });
  };

  const getBeaconLineFeature = (
    point1,
    point2,
    rssi,
    distance,
    isActive,
    notUsed
  ) => {
    const feature = new Feature({
      geometry: new LineString([
        transformCoordinates(point1),
        transformCoordinates(point2)
      ])
    });

    const color = isActive ? 'red' : notUsed ? 'gray' : 'black';
    const geometry = feature.getGeometry();
    const getStyles = zIndex => {
      const styles = [
        // linestring
        new Style({
          stroke: new Stroke({ color, width: 3 })
        }),
        new Style({
          text: new Text({
            font: '14px Roboto',
            textAlign: 'center',
            justify: 'center',
            text: `${rssi} dB, ${distance.toFixed(1)} m`,
            fill: new Fill({
              color: 'white'
            }),
            backgroundFill: new Fill({
              color
            }),
            backgroundStroke: new Stroke({
              color,
              width: 14,
              lineCap: 'round',
              lineJoin: 'round'
            })
          }),
          zIndex
        })
      ];
      geometry.forEachSegment(function(start, end) {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        const rotation2 = Math.atan2(-dy, -dx);
        // arrows
        styles.push(
          new Style({
            geometry: new Point(end),
            image: new Icon({
              src: require('@/assets/arrow.png'),
              anchor: [0.75, 0.5],
              rotateWithView: true,
              rotation: -rotation,
              scale: 0.25,
              color
            })
          })
        );
      });

      return styles;
    };

    feature.setStyle(getStyles(undefined));
    map.on('click', function(evt) {
      const featureAtPixel = map.forEachFeatureAtPixel(evt.pixel, function(
        feature
      ) {
        return feature;
      });
      if (featureAtPixel === feature) {
        feature.setStyle(getStyles(1000));
      } else {
        feature.setStyle(getStyles(undefined));
      }
      trackLayer.changed();
    });

    return feature;
  };

  const showPointInfo = ({
    position,
    beacons,
    anchorBeaconId,
    notUsedBeacons
  }) => {
    infoSource.clear();
    // select track point
    selectedTrackPointFeature.setGeometry(
      new Point(transformCoordinates(position))
    );
    selectedTrackPointFeature.setStyle(selectedTrackPointStyle);
    // add lines for beacons
    beacons.forEach((beacon, index) => {
      const { lon, lat } = beacon.sourcePosition;
      infoSource.addFeature(
        getBeaconLineFeature(
          position,
          [lon, lat],
          beacon.rssi,
          beacon.distance,
          beacon.id === anchorBeaconId
        )
      );
    });
    // add lines for notused beacons
    notUsedBeacons.forEach((beacon, index) => {
      const { lon, lat } = beacon.sourcePosition;
      infoSource.addFeature(
        getBeaconLineFeature(
          position,
          [lon, lat],
          beacon.rssi,
          beacon.distance,
          false,
          true
        )
      );
    });
    // add beacons
    beacons.forEach(beacon => {
      infoSource.addFeature(getBeaconFeature(beacon));
    });
    notUsedBeacons.forEach(beacon => {
      const feature = getBeaconFeature(beacon);
      feature.set('notUsed', true);
      infoSource.addFeature(feature);
    });
  };

  return {
    setTrack,
    clearTrack,
    trackLayer,
    infoLayer,
    setMapToTrackCenter,
    setTrackVisible,
    isPlaying,
    startPlayTrack,
    stopPlayTrack,
    pausePlayTrack,
    nextTrackLine,
    prevTrackLine,
    currentTrackLineIndex,
    isAnimatingMode,
    setAnimatingMode,
    speed,
    showPointInfo
  };
}
