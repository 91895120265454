import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { castToVueI18n, createI18n } from 'vue-i18n-bridge';

import messages from './global.i18n.json';

Vue.use(VueI18n, { bridge: true });

export const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: 'en',
      fallbackLocale: 'en',
      silentTranslationWarn: false,
      missingWarn: true,
      messages
    },
    VueI18n
  )
);
