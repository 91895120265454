import {
  flattenObject,
  getObjectImageUrlOrDefault,
  getObjectPropertyByKey,
  patchObjectsByObjectId,
  savedLat,
  savedLon,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery } from '@vue/apollo-composable';
import { computed, inject, provide, watch, ref } from 'vue-demi';
import objectsSchema from '../api/object-list.graphql';
import { useMap } from '@/compositions/map';
import { ALARM_STATUSES } from '@/modules/objects/compositions/objectStatus';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { throttle } from 'lodash-es';
import { promisifyQuery } from '@/utils';
import { checkEntityBySchemaTags, EntityEnum } from '@/provider/entities';
import { ColorNames } from '@/compositions/map/utils/data';

const THROTTLE_UPDATE_OBJECTS_VALUE = 2000;
const THROTTLE_UPDATE_OBJECTS_AFTER_MOVING_VALUE = 300;

export function createObjectsStore() {
  const {
    onMapMount,
    isMoving,
    markers: {
      markerIds,
      selectedMarkerIds,
      removeMarker,
      clearMarkers,
      addMarkers
    }
  } = useMap();

  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    objectsSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const { isCurrentGeotag } = useGeotags();
  const resultList = ref([]);
  const shouldUpdateAfterMoving = ref(false);
  let timer = null;

  watch(
    () => isMoving.value,
    v => {
      if (!v && shouldUpdateAfterMoving.value) {
        shouldUpdateAfterMoving.value = false;
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(
          () => throttledFunc({ data: result.value }),
          THROTTLE_UPDATE_OBJECTS_AFTER_MOVING_VALUE
        );
      }
    }
  );
  const throttledFunc = throttle(({ data }) => {
    if (isMoving.value) {
      shouldUpdateAfterMoving.value = true;
      return;
    }
    resultList.value = (data?.objects || []).map(i => {
      const obj = flattenObject(i);
      return {
        ...obj,
        positionGeotagIdPropertyId: getObjectPropertyByKey(
          i,
          'positionGeotagId'
        )?.id,
        color: obj.currentStateColor || ColorNames['default'],
        alarmed: obj.statusesAlarm === ALARM_STATUSES.TRIGGERED,
        iconSrc: getObjectImageUrlOrDefault(obj.currentStateIcon),
        lat: savedLat(obj.positionPoint.lat),
        lon: savedLon(obj.positionPoint.lon),
        alt: obj.positionPoint.alt,
        geotagIds: obj.geotags.map(geotag => geotag.object2?.id),
        objectGroupIds: obj.objectGroups.map(group => group.object1?.id)
      };
    });
  }, THROTTLE_UPDATE_OBJECTS_VALUE);

  onResult(throttledFunc);

  const list = computed(() =>
    resultList.value.filter(object => {
      return (
        !!isCurrentGeotag([object.positionGeotagId, ...object.geotagIds]) ||
        selectedMarkerIds.value[object.id]
      );
    })
  );

  const listMap = computed(() => {
    return list.value.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  });

  onMapMount.on(() => {
    watch(
      () => list.value,
      () => {
        const markers = [];
        markerIds.value.forEach(markerId => {
          if (!listMap.value[markerId]) {
            removeMarker(markerId);
            return;
          }
          markers.push(listMap.value[markerId]);
        });
        addMarkers(markers);
      }
    );

    watch(
      () => markerIds.value,
      markerIds => {
        clearMarkers();
        addMarkers(
          markerIds
            .filter(markerId => listMap.value[markerId])
            .map(markerId => listMap.value[markerId])
        );
      },
      {
        immediate: true
      }
    );
  });

  subscribeToMore({
    document: objectsSchema.listenObjectList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('objects list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'ObjectsToObject': {
          // check that it's object group entity
          if (
            checkEntityBySchemaTags(
              EntityEnum.ObjectGroup,
              relatedNode.object1?.schemaTags || []
            )
          ) {
            const { object2Id } = relatedNode;

            // patch current objects
            const patchedObjects = patchObjectsByObjectId(
              object2Id,
              'objectGroups',
              relatedNode,
              previousResult.objects
            );

            if (patchedObjects) {
              return {
                objects: patchedObjects
              };
            }
          }
          if (
            checkEntityBySchemaTags(
              EntityEnum.Geotag,
              relatedNode.object2?.schemaTags || []
            )
          ) {
            const { object1Id } = relatedNode;

            // patch current objects
            const patchedObjects = patchObjectsByObjectId(
              object1Id,
              'geotags',
              relatedNode,
              previousResult.objects
            );

            if (patchedObjects) {
              return {
                objects: patchedObjects
              };
            }
          }
          break;
        }
        case 'Object': {
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list,
    listMap,
    resultList
  };
}

export const ObjectsProviderSymbol = Symbol('Objects identifier');

export const useObjectsProvider = () => {
  provide(ObjectsProviderSymbol, createObjectsStore());
};

export function useObjects() {
  return inject(ObjectsProviderSymbol);
}
