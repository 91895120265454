export default [
  {
    id: 'logo',
    type: 'MenuItemLogo'
  },
  {
    id: 'divider',
    type: 'MenuItemDivider'
  },
  {
    id: 'account',
    title: 'Account',
    icon: '$account',
    hideOnDesktop: true,
    type: 'MenuItemDefault',
    action: ({ popup }) => {
      popup.open({
        component: () => import('@/modules/account/AccountMenuMobile.vue')
      });
    }
  },
  {
    id: 'applications',
    title: 'Applications',
    icon: '$apps',
    hideOnDesktop: true,
    type: 'MenuItemDefault',
    action: ({ popup }) => {
      popup.open({
        component: () => import('@/modules/account/AppSelectMobile.vue')
      });
    }
  },
  {
    id: 'divider',
    type: 'MenuItemDivider',
    hideOnDesktop: true
  },
  {
    id: 'objects',
    title: 'Objects',
    icon: '$object',
    type: 'MenuItemDefault',
    to: {
      name: 'objects'
    }
  },
  {
    id: 'objectsGroups',
    title: 'Groups',
    icon: '$group',
    type: 'MenuItemGroup',
    children: []
  },
  {
    id: 'landmarks',
    title: 'Landmarks',
    icon: '$landmark',
    type: 'MenuItemDefault',
    to: {
      name: 'landmarks'
    }
  },
  {
    id: 'geozones',
    title: 'Geozones',
    icon: '$geozone',
    type: 'MenuItemDefault',
    to: {
      name: 'geozones'
    }
  },
  {
    id: 'divider',
    type: 'MenuItemDivider'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: '$settings',
    type: 'MenuItemGroup',
    disabled: true,
    children: [
      {
        id: 'settingsGeneral',
        title: 'General',
        type: 'MenuItemDefault',
        action: ({ popup }) => {
          popup.open({
            component: () => import('@/modules/settings/general')
          });
        }
      },
      {
        id: 'settingsGeotags',
        title: 'Geotags',
        type: 'MenuItemDefault',
        to: {
          name: 'geotags'
        }
      },
      {
        id: 'settingsPlans',
        title: 'Plans',
        type: 'MenuItemDefault',
        to: {
          name: 'plans'
        }
      },
      {
        id: 'settingsTypes',
        title: 'Types',
        type: 'MenuItemDefault',
        to: {
          name: 'types'
        }
      },
      {
        id: 'settingsCollections',
        title: 'Collections',
        type: 'MenuItemDefault',
        disabled: true
      },
      {
        id: 'settingsDataForwarding',
        title: 'Data forwarding',
        type: 'MenuItemDefault',
        to: {
          name: 'connections'
        }
      }
    ]
  },
  {
    id: 'divider',
    type: 'MenuItemDivider'
  },
  {
    id: 'notifications',
    title: 'Notifications',
    icon: '$alert',
    type: 'MenuItemDefault',
    to: {
      name: 'notifications'
    }
  },
  {
    type: 'MenuItemDefault',
    id: 'about',
    title: 'About',
    icon: 'mdi-information',
    action: ({ popup }) => {
      popup.open({ component: () => import('@/modules/about/index.vue') });
    }
  },
  {
    type: 'MenuItemDefault',
    id: 'help',
    title: 'Help',
    icon: 'mdi-help-circle',
    action: () => {
      window.open(
        window.__pixelConfig?.APP_DOC ||
          'https://pixel-networks.atlassian.net/wiki/spaces/PCV3/pages/11403297/PixelMonitor',
        '_blank'
      );
    }
  }
];
